import React, { Fragment } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import { css } from 'styled-components/macro';
import SEO from '../components/SEO';
import Header from '../components/Header';
import FooterPre from '../components/FooterPre';

const blogQuery = graphql`
  {
    page: datoCmsBlogisivu {
      title
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    posts: allDatoCmsBlogpost(sort: { fields: position, order: ASC }) {
      edges {
        node {
          id
          title
          slug
          kuva {
            fluid(maxWidth: 900, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
            alt
          }
        }
      }
    }
  }
`;

const Blog = () => {
  const data = useStaticQuery(blogQuery);

  return (
    <Fragment>
      <SEO meta={data.page.seoMetaTags} />
      <div className="hamburgerColorBlue">
        <Header />
      </div>
      <main
        css={`
          display: grid;
          padding-top: 60px;
          grid-template-columns: repeat(2, 1fr);
          @media (max-width: 780px) {
            grid-template-columns: repeat(1, 1fr);
          }
          article {
            position: relative;
            height: 500px;
          }
          .imgArticle {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .titleArticle {
            position: absolute;
            bottom: 50px;
            left: 50px;
            max-width: 650px;
            width: 80%;
            font-family: utopia-std;
            font-size: 36px;
            line-height: 110%;
            color: #fff;
            text-transform: none;
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            padding: 10px;
          }
          @media (max-width: 600px) {
            article {
              height: 300px;
            }
            .titleArticle {
              bottom: 20px;
              left: 20px;
              font-size: 26px;
            }
          }
          .layer {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgb(0, 0, 0);
            background: linear-gradient(
              3deg,
              rgba(0, 0, 0, 0.35) 0%,
              rgba(0, 0, 0, 0) 100%
            );
          }
        `}
      >
        {data.posts.edges.map((item, index) => {
          return (
            <article key={index}>
              <Link to={`/blogi/${item.node.slug}`}>
                <Img
                  className="imgArticle"
                  fluid={item.node.kuva.fluid}
                  alt={item.node.kuva.alt}
                />
                <div className="layer" />
                <h2 className="titleArticle">{item.node.title}</h2>
              </Link>
            </article>
          );
        })}
      </main>
      <FooterPre />
    </Fragment>
  );
};

export default Blog;
